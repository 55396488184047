<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <v-app>
          <v-alert border="bottom" colored-border type="warning" elevation="2">
            Bitte bearbeiten Sie ihre Signatur, um die volle Funktionalität dieses Dienstes zu gewährleisten.
            Beachten Sie hierbei die HTML Struktur.
          </v-alert>
          <br />
          <h1>E-Mail Konfiguration</h1>
          <!--begin::Info-->

          <div class="vld-parent">
            <loading
                :active.sync="isLoading"
                :can-cancel="true"
                :is-full-page="true"
            ></loading>
          </div>

          <div class="d-flex align-items-center pr-2 mb-6">
            <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
              >Legen Sie hier Ihre eigene E-Mail-Signatur fest. Diese Signatur wird in ausgehenden Rechnungen sichtbar sein.</span
            >
            <div class="symbol symbol-50">
              <span class="symbol-label bg-light-light">
                <img
                  src="/media/svg/icons/Communication/Mail-locked.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </div>
          <!--end::Info-->
          <div>
            <div v-if="message">
              <v-alert dense text type="success">
                {{ message }}
              </v-alert>
            </div>

            <form>
              <v-text-field
                v-model="form.name"
                disabled
                label="Name"
              ></v-text-field>
              <v-text-field
                v-model="form.email"
                disabled
                label="E-Mail"
              ></v-text-field>
              <v-textarea
                v-model="form.signature"
                :error-messages="requiredErrors"
                label="Signatur (HTML)"
                auto-grow
                required
                @input="$v.form.signature.$touch()"
                @blur="$v.form.signature.$touch()"
              ></v-textarea>

              <v-btn
                v-if="message || sendTestButtonVisibility"
                class="mr-4"
                @click="onSubmitEmailTest"
              >
                Test E-Mail versenden
              </v-btn>
              <v-btn class="mr-4" @click="onSubmit" color="primary">
                senden
              </v-btn>
            </form>
          </div>
        </v-app>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { LOGOUT } from "@/core/services/store/auth.module";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Email",
  components: {
    Loading
  },
  mixins: [validationMixin],
  validations: {
    form: {
      signature: { required }
    }
  },
  data: () => ({
    message: "",
    sendTestButtonVisibility: false,
    exampleSignatuer:
      "<p>Best regards / Mit freundlichen Grüßen\n" +
      "<br />Max Mustermann\n" +
      "<br />Buchhaltung\n" +
      "</p>\n" +
      "<p>Tel.: +49 421 999\n" +
      "<br />Mobil: +49 171 999999\n" +
      '<br />E-Mail: <a href="mailto:info@itexplus.de">info@itexplus.de</a>\n' +
      '<br />Webseite: <a href="https://www.itexplus.de/">www.itexplus.de/</a></p>',
    form: {
      email: "-",
      name: "-",
      signature: ""
    },
    isLoading: false
  }),
  created: function() {
    this.isLoading = true;
    ApiService.query("emailsettings")
      .then(({ data }) => {
        this.isLoading = false;
        //console.log(data);
        this.form = data;

        if (!data.signature) {
          //console.log(data.signature);
          this.form.signature = this.exampleSignatuer;
          this.sendTestButtonVisibility = false;
        } else {
          this.sendTestButtonVisibility = true;
        }
      })
      .catch(({ response }) => {
        this.isLoading = false;
        if (response.status === 401) {
          if (response.status === 401) {
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "login" }));
          }
        }
      });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usermanagement" }]);
  },
  methods: {
    onSubmit(evt) {
      this.$v.$touch();

      this.isLoading = true;
      if (this.$v.form.signature.required) {
        ApiService.put("emailsettings/setting", {
          signature: this.form.signature
        })
          .then(({ data }) => {
            this.message = data.message;
            this.isLoading = false;
          })
          .catch(({ response }) => {
            this.isLoading = false;
            if (response.status === 401) {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          });
      }

      evt.preventDefault();
    },
    onSubmitEmailTest() {
      this.isLoading = true;
      this.sendTestButtonVisibility = true;
      ApiService.query("emailsettings/emailtest")
        .then(({ data }) => {
          this.isLoading = false;
          this.message = data.message;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          if (response.status === 401) {
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "login" }));
          }
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  computed: {
    requiredErrors() {
      const errors = [];
      if (!this.$v.form.signature.$dirty) return errors;
      !this.$v.form.signature.required && errors.push("Field is required");

      return errors;
    }
  }
};
</script>

<style scoped></style>
